//
//  Base Styles
//  ____________________________________________


//
//  Common
//  ----------------------------------------------

.box.box-conpany-information {
    display: flex;
    display: -webkit-flex;
    .image {
        margin-right:20px;
    }
}
