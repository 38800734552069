/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.box.box-conpany-information {
  display: flex;
  display: -webkit-flex;
}

.box.box-conpany-information .image {
  margin-right: 20px;
}
